$primary-color: #002469 !default
$text-color: #ffff !default
$dark-text-color: #343a40 !default
$muted-text: #6c757d !default
$bg-color: #f1f1f1 !default
$bg-light: #f8f9fa !default
$light-gray-color: #ced4da !default
$light-color: #fff !default
$dark-color: #000 !default
$red-color: #ff0000 !default
$green-color: #008000 !default
$blue-bootstrap-color: #0d6efd !default
$warning-bootstrap-color: #ffc107 !default
$danger-bootstrap-color: #dc3545 !default
$dark-bootstrap-color: #212529 !default
$font-bold: bold !default
$font-bolder: bolder !default
$light-font-family: Segoe UI !default
$medium-font-family: Segoe UI !default
$regular-font-family: Segoe UI !default
$semibold-font-family: Segoe UI !default
$bold-font-family: Segoe UI !default
$position-fixed: fixed !default
$max-z-index: 999 !default
$border-radius: 10px !default
//MEDIA QUERY RESOLUTION
$min-screen-resolution-width: 320px !default
$max-screen-resolution-width: 991px !default

//PRODUCT COUNTER
$product-counter-background-color: $light-color !default
$product-counter-border-color: 1px solid $light-gray-color !default
$custom-icons-path: '../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons'

//CUSTOM
$header-background-links-color: #00337f