@import '_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'

.main-navigation nav button a
    color: $light-color !important

#headerCartIcon
    color: $light-color !important

.default-cart-counter
    color: $light-color !important

.srs-icon-help-circle
    color: $light-color !important

/* For Mobile View */
@media screen and (min-width: $min-screen-resolution-width) and (max-width: $max-screen-resolution-width)
    #headerLanguageText
        color: $light-color !important
    #myAccountDropdownMenu
        color: $light-color !important